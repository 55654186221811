import Vue from "vue";
import nodeApi from "../../api/nodeApi";

const state = {
    eventList: [],


}

const getters = {
    getEventList: (state) => {
        return state.eventList;
    }
}

const actions = {
    getEventList: (context, payload) => {

        Vue.$log.debug(['getEventList action ', payload]);

        nodeApi.postEventList(payload).then( res => {
            Vue.$log.debug(['store postEventList res', res]);
            if (res.data.success) {
                context.commit('getEventList', res.data.event_list);
            }
        }).catch( err => {
            Vue.$log.error(err.toString());
        });
    }
}

const mutations = {
    getEventList: (state, payload) => {
        state.eventList = payload;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
