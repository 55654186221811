<template>
  <div>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="상품관리" title="상품등록" />
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
<!--            <div class="card-header">-->
<!--              <h5>Add Product</h5>-->
<!--            </div>-->
            <div class="card-body">
              <div class="form-row">
                <div class="col-6 ">
                  <div class="form-group">
                    <label class="form-label d-inline" for="goodsName">상품명</label>
                    <input type="text" class="form-control" id="goodsName" name="goods_name" v-model="item.goods_name" aria-describedby="adNameHelp" placeholder="상품명" required>
                  </div>
                  <div class="form-group d-none">
                    <label class="form-label">상품구분</label>
                    <label class="custom-control custom-radio custom-control-inline ml-2">
                      <input type="radio" class="custom-control-input" name="goods_type" value="GOODS_TYPE01" checked v-model="item.goods_type">
                      <span class="custom-control-label">희망거래 판매용</span>
                    </label>
                    <label class="custom-control custom-radio custom-control-inline">
                      <input type="radio" class="custom-control-input" name="goods_type" value="GOODS_TYPE02" v-model="item.goods_type">
                      <span class="custom-control-label">조각모음 당첨전용</span>
                    </label>
                    <div class="form-inline d-inline-block">
                      <small class="form-text text-muted d-inline">조각모음 당첨전용 : 이벤트 조각 수집 완료 시 보상으로 제공될 수 있는 상품</small>
                    </div>
                  </div>
                  <div class="form-group" style="display: none;">
                    <label class="form-label">옵션여부</label>
                    <label class="custom-control custom-radio custom-control-inline ml-2">
                      <input type="radio" class="custom-control-input" name="option_yn" value="N" checked>
                      <span class="custom-control-label">미사용</span>
                    </label>
                    <label class="custom-control custom-radio custom-control-inline ml-2">
                      <input type="radio" class="custom-control-input" name="option_yn" value="Y">
                      <span class="custom-control-label">사용</span>
                    </label>
                  </div>
                  <div class="form-group" id="option-container" style="display: none;">
                    <label class="form-label d-inline" for="optionPlus">옵션추가</label><small class="form-text text-muted d-inline ml-2"></small>
                    <div id="optionPlus" class="fa fa-plus-circle fa-lg text-primary"></div>
                    <div id="optionStep" class="row">
                      <div class="col-12 input-group mb-1">
                                                        <span class="input-group-prepend">
                                                            <span class="input-group-text">1</span>
                                                        </span>
                        <input type="text" class="form-control form-inline col input-option" name="option_names[]" placeholder="옵션명">
                        <input type="text" class="form-control form-inline col-3 input-option" name="option_prices[]" placeholder="옵션추가금액">
                        <span class="input-group-append">
                                                            <span class="input-group-text"><i class="fa fa-check-circle fa-lg" aria-hidden="true"></i></span>
                                                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="form-label">전체판매 수량</label>
                    <div class="form-inline d-inline-flex ml-2">
                      <input type="number" class="form-control col-4" name="goods_qty" value="" required v-model="item.goods_qty">
                      <span class="ml-1">등록 후 수정 불가</span>
                    </div>
                  </div>
                  <div class="form-group d-none">
                    <label class="form-label d-inline">기간설정여부 </label>
                    <label class="switch switch-icon switch-outline-alt-primary form-control-label ml-2 mb-0 align-middle">
                      <input type="checkbox" class="switch-input form-check-input form-control-sm" name="use_date" value="" id="isDate" checked>
                      <span class="switch-label"></span>
                      <span class="switch-handle"></span>
                    </label>
                  </div>
                  <div class="form-group">
                    <label class="form-label d-inline" for="ct_date">판매기간</label>
                    <div class="input-daterange form-inline" id="dateTerm">
                      <date-picker v-model="item.start_date" format="YYYY.MM.DD" type="date" valueType="format" />
<!--                      <input type="text" class="form-control d-inline" name="start_date" id="startDate" readonly required />-->
                      <span class="mx-2 bg-white">~</span>
                      <date-picker v-model="item.end_date" format="YYYY.MM.DD" type="date" valueType="format" />
<!--                      <input type="text" class="form-control d-inline" name="end_date" id="endDate" readonly required />-->
                    </div>
                  </div>
                  <div class="form-group">
                    <label class="form-label">판매금액</label>
                    <div class="form-inline d-inline-flex ml-2">
                      <input type="number" class="form-control" id="goodsPrice" name="goods_price" aria-describedby="adNameHelp" placeholder="판매금액" required v-model="item.goods_price">
                    </div>

                  </div>
                  <div id="use-discount" class="d-none">
                    <div class="form-group">
                      <label class="form-label">할인 유무</label>
                      <label class="switch switch-icon switch-outline-alt-primary form-control-label ml-2" style="margin-top: -4px;">
                        <input type="checkbox" id="useDiscount" name="use_discount" class="switch-input form-check-input form-control-sm" value="">
                        <span class="switch-label"></span>
                        <span class="switch-handle"></span>
                      </label>
                    </div>
                  </div>
                  <div id="discount-wrap">
                    <div class="form-group d-none">
                      <label class="form-label">할인구분</label>
                      <label class="custom-control custom-radio custom-control-inline ml-2">
                        <input type="radio" class="custom-control-input" name="discount_type" value="DISCOUNT_TYPE01" checked v-model="item.discount_type">
                        <span class="custom-control-label">비율</span>
                      </label>
                      <label class="custom-control custom-radio custom-control-inline">
                        <input type="radio" class="custom-control-input" name="discount_type" value="DISCOUNT_TYPE02" v-model="item.discount_type">
                        <span class="custom-control-label">금액</span>
                      </label>
                      <div class="form-inline d-inline-block">
                        <small id="EventBlockHelp" class="form-text text-muted d-inline">비율 : 일정비율 범위내 딜 제공,</small>
                        <small id="EventBlockHelp" class="form-text text-muted d-inline ml-1">금액 : 지정금액 내 딜 제공</small>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="form-label d-inline" for="discountStep">할인범위</label><small class="form-text text-muted d-inline ml-2">할인범위 금액를 입력하세요.</small>
                      <span @click="discountPlus"><i  class="fa fa-plus-circle fa-lg text-primary"></i></span>
                      <div id="discountStep" class="row">
                        <div class="col-12 input-group mb-1" v-for="(item, index) in discountRange">
                          <span class="input-group-prepend">
                              <span class="input-group-text">{{ index+1 }}</span>
                          </span>
                          <input type="text" class="form-control form-inline col-3 discount-starts" name="discount_starts[]" placeholder="시작범위 금액" required v-model="item.discount_starts">
                          <span class="input-group-prepend">
                              <span class="input-group-text">~</span>
                          </span>

                          <input type="text" class="form-control form-inline col-3 discount-ends" name="discount_ends[]" placeholder="종료범위 금액" required v-model="item.discount_ends">
                          <input type="text" class="form-control form-inline discount-qtys" name="discount_qtys[]" placeholder="할인 수량" required v-model="item.discount_qtys">
                          <span class="input-group-append">
                              <span class="input-group-text" @click="discountMinus(item)"><i class="fa  fa-lg" :class="index > 0 ? 'fa-minus-circle text-danger' : 'fa-check-circle' " aria-hidden="true"></i></span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="row">
                    <div class="col-8">
                      <div class="form-group">
                        <label class="form-label d-inline" for="goodsThumbnail">상품메인 이미지</label>
                        <small class="text-muted ml-2">최적화 사이즈 : 600 * 600</small>

                        <div class="custom-file">
                        <input type="file" class="custom-file-input" name="goods_thumbnail" id="goodsThumbnail" accept="image/*" required @change="changeMain" >
                          <label class="custom-file-label">{{mainFile}}</label>
                        </div>
                      </div>
                    </div>
                    <div class="col show-img" id="showThumbnail"><img :src="showMain" class="box-input-file" /></div>
                  </div>
                  <div class="form-group">
                    <label class="form-label d-inline" for="goodsDetail">상품상세 이미지</label>
                    <small id="adImageHelp" class="text-muted ml-2">다중 선택 가능</small>
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" name="goods_detail[]" id="goodsDetail" multiple accept="image/*" required @change="changeDetail">
                      <label class="custom-file-label">{{detailFile}}</label>
                    </div>
                  </div>
                  <section class="character-twin text-center">
                    <div id="sliceWrap" class="slice-wrap">

                    </div>
                    <div id="showImg" class="text-center">
                      <div class="d-inline-block align-top px-1" style="width: 33%" v-for="(i, index) in detailImages">
                        <img :src="i" class="box-input-file w-100" />
                      </div>
                    </div>
                  </section>

                </div>
              </div>
              <div class="text-center">
                <button type="button" class="btn btn-primary mr-1" id="btn-save" @click="save">등록완료</button>
                <router-link to="/goods/goodsList"><a type="button" class="btn btn-danger ml-1">돌아가기</a></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ko';
import nodeApi from "../../api/nodeApi";
import Vue from "vue";
import router from "../../router";

export default {
  components: {DatePicker},
  data() {
    return {
      counter: 0,
      content: "<h1>Some initial content</h1>",
      image: [],
      imageUrl: "../../assets/images/pro3/33.jpg",
      getImgUrl:"@assets/images/",
      discountRange: [{discount_starts: '', discount_ends: '', discount_qtys: ''}],
      item: {
        detail_images: [],
        goods_type: 'GOODS_TYPE01',
        discount_type: 'DISCOUNT_TYPE02'
      },
      showMain: null,
      mainFile: '상품메인 이미지 파일 선택',
      detailImages: [],
      detailFile: '상품상세 이미지 파일 선택'
    };
  },
  created() {
    this.$log.debug(['created', this.item]);
  },
  methods: {
    save() {
      this.$log.debug(['save', this.item, this.discountRange]);
      let managerInfo = localStorage.getItem('userInfo');
      if (managerInfo) {
        managerInfo = JSON.parse(managerInfo);
      }
      const frm = new FormData();
      frm.append('site_code', managerInfo.site_code);
      frm.append('goods_name', this.item.goods_name);
      frm.append('goods_thumbnail', this.item.goods_thumbnail);
      if (this.item.detail_images) {
        for (let i = 0; i < this.item.detail_images.length; i++) {
          frm.append('detail_images', this.item.detail_images[i]);
        }
      }

      frm.append('goods_price', this.item.goods_price);
      frm.append('goods_type', this.item.goods_type);
      frm.append('goods_qty', this.item.goods_qty);
      frm.append('start_date', this.item.start_date);
      frm.append('end_date', this.item.end_date);
      frm.append('discount_type', this.item.discount_type);
      frm.append('discount_range', JSON.stringify(this.discountRange));
      nodeApi.postGoods(frm).then( res => {
        console.log(['goods hash ', res.data.result_hash]);
        router.push("/goods/goodsList");
      }).catch( err => {
        this.$log.error(err.toString());
      });
    },
    changeDetail(e) {
      let file = e.target.files;
      this.$log.debug(['file', file]);
      if (file && file.length > 0) {
        this.detailFile = '';
        this.detailImages = [];
        for (let i = 0; i < file.length; i++) {
          this.detailFile += file[i].name;
          this.item.detail_images.push(file[i]);
          if (i < file.length-1) this.detailFile += ',';
          const reader = new FileReader();
          reader.onload = e => {
            this.detailImages.push(e.target.result);
          };
          reader.readAsDataURL(file[i]);
        }
      }
    },
    decrement() {
      if (this.counter > 1) this.counter--;
    },
    onFileChange(e) {
      var files = e.target.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      var reader = new FileReader();
      reader.onload = e => {
        this.image.push(e.target.result);
      };
      reader.readAsDataURL(file);
    },
    removeImage: function(index) {
      console.log("index", index);
      this.image.splice(index, 1);
      //  this.image = "";
    },
    discountPlus() {
      this.$log.debug(['discountPlus', this.discountRange.length])
      let idx = this.discountRange.length + 1;
      this.discountRange.push({discount_starts: '', discount_ends: '', discount_qtys: ''});
    },
    discountMinus(idx) {
      this.$log.debug(['discountMinus', idx, this.discountRange.length])
      this.discountRange.splice(this.discountRange.length-1,1);
      // this.discountRange.sort()
    },
    changeMain(e) {
      this.$log.debug(['changeMain', e]);
      let file = e.target.files;

      this.$log.debug(['file', file, file[0].name]);
      this.mainFile = file[0].name;
      this.item.goods_thumbnail = file[0];
      const reader = new FileReader();
      reader.onload = e => {
        this.showMain = e.target.result;

      };
      reader.readAsDataURL(file[0]);
    }
  }
};
</script>

<style scoped>
 .ck-content { height:500px; }
 .icon{
position: absolute;
  top: 8px;
  right: 16px;
 }
 .show-img img { width: 100%; }
</style>
