<template>
  <div>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="이벤트관리" :title="item.event_name ? '이벤트 수정' : '이벤트 등록'" />
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <!-- 이벤트 제목, 이벤트 기간 -->
              <div class="row product-adding">
                <div class="col-xl-6  needs-validation">
                  <div class="form-group">
                    <label
                      for="event_name"
                      class="col-form-label pt-0"
                    ><span>*</span> 이벤트제목 <small class="text-muted">사용자에게 보여지는 이벤트 제목입니다.</small></label
                    >
                    <ValidationProvider
                      name="이벤트제목"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <input
                        class="form-control"
                        id="event_name"
                        type="text"
                        v-model="item.event_name"
                        required=""
                      />
                      <span
                        class="block text-danger text-xs absolute bottom-0 left-0"
                      >{{ errors[0] }}</span
                      >
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-xl-6  needs-validation">
                  <div class="form-group">
                    <label class="form-label d-inline"><span>*</span> 이벤트 기간</label>
                    <div class="input-daterange form-inline">
                      <ValidationProvider name="이벤트시작일" rules="required" v-slot="{ errors }" >
                        <date-picker v-model="item.start_date" format="YYYY.MM.DD" type="date" valueType="format" />
                      </ValidationProvider>
                      <!--                      <input type="text" class="form-control d-inline" name="start_date" id="startDate" readonly required />-->
                      <span class="mx-2 bg-white">~</span>
                      <date-picker v-model="item.end_date" format="YYYY.MM.DD" type="date" valueType="format" />
                      <!--                      <input type="text" class="form-control d-inline" name="end_date" id="endDate" readonly required />-->
                    </div>
                  </div>
                </div>
              </div>
              <!-- 수집원본 이미지, 미션이미지 영역 -->
              <div class="row product-adding">
                <div class="col-xl-6  needs-validation">
                  <div class="form-group">
                    <label class="form-label d-inline" for="adImage">수집원본 이미지</label><small id="adImageHelp" class="text-muted ml-2">최적화 사이즈 : 600 * 672, 권장 포맷 : png, jpg</small>
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" name="event_image" id="adImage" accept="image/*" required @change="imagePreview">
                      <label class="custom-file-label">{{file_name}}</label>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6  needs-validation">
                  <div class="form-group">
                    <span class="form-label">이미지 미리보기</span>

                  </div>
                  <section class="character-twin text-center">
                    <div id="sliceWrap" class="slice-wrap">

                    </div>
                    <div id="showImg" class="text-center" >
                      <img v-if="preview" :src="preview" class="img-fluid">
                    </div>
                  </section>
                </div>
              </div>
              <!-- 미션이미지 영역 -->
              <div class="row product-adding">
                <div class="col-xl-6  needs-validation">
                  <div class="form-group">
                    <label class="form-label d-inline" for="adImage">미션이미지</label>
<!--                    <small id="adImageHelp" class="text-muted ml-2">최적화 사이즈 : 600 * 672, 권장 포맷 : png, jpg</small>-->
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" name="event_image" accept="image/*" required @change="imageMissionPreview">
                      <label class="custom-file-label">{{mission_file_name}}</label>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6  needs-validation">
                  <div class="text-center" >
                    <img v-if="previewMission" :src="previewMission" class="img-fluid">
                  </div>
                </div>
              </div>
              <!-- 이밴트 상세이미지 영역 -->
              <div class="row product-adding">
                <div class="col-xl-6  needs-validation">
                  <div class="form-group">
                    <label class="form-label d-inline" for="adImage">이벤트 상세이미지</label>
                    <!--                    <small id="adImageHelp" class="text-muted ml-2">최적화 사이즈 : 600 * 672, 권장 포맷 : png, jpg</small>-->
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" name="event_image" accept="image/*" required @change="imageDetailPreview">
                      <label class="custom-file-label">{{detail_file_name}}</label>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6  needs-validation">
                  <div class="text-center" >
                    <img v-if="previewDetail" :src="previewDetail" class="img-fluid">
                  </div>
                </div>
              </div>
              <div class="row product-adding">
                <div class="col-xl-6  needs-validation">

                  <div class="form-group">
                    <label class="form-label">기본 설명</label>
                    <textarea class="form-control" rows="4" v-model="item.event_desc"></textarea>
                  </div>

                  <div class="form-group">
                    <label class="form-label">보상 유형</label>
                    <label class="custom-control custom-radio custom-control-inline">
                      <input type="radio" class="custom-control-input" name="reward_type" value="REWARD_TYPE01" v-model="item.reward_type" @change="resetReward">
                      <span class="custom-control-label">동일보상형</span>
                    </label>
                    <label class="custom-control custom-radio custom-control-inline">
                      <input type="radio" class="custom-control-input" name="reward_type" value="REWARD_TYPE02" v-model="item.reward_type" @change="resetReward">
                      <span class="custom-control-label">차등보상형</span>
                    </label>
                    <label class="custom-control custom-radio custom-control-inline">
                      <input type="radio" class="custom-control-input" name="reward_type" value="REWARD_TYPE03" v-model="item.reward_type" @change="resetReward">
                      <span class="custom-control-label">택일형</span>
                    </label>
                    <small class="form-text text-muted">동일보상형 : 지정 순위까지 동일한 보상 지급</small>
                    <small class="form-text text-muted">차등보상형 : 각 단계별 보상을 설정합니다.</small>
                    <small class="form-text text-muted">택일형 : 보상중 하나를 선택합니다.</small>
                  </div>
                  <div class="form-group">
                    <label class="form-label d-inline">보상지급품목</label><small id="EventBlockHelp" class="form-text text-muted d-inline ml-2">보상지급 순위와 보상품목을 입력하세요. 순위는 숫자만 입력하세요.</small>
                    <span v-if="item.reward_type !== 'REWARD_TYPE01'" @click="rewardPlus" class="d-inline-flex" >
                      <i class="fa fa-plus-circle fa-lg text-primary " ref="rewardPlus"></i>
                    </span>
                    <div id="rewardSame" class="row" v-if="!item.reward_type || item.reward_type === 'REWARD_TYPE01'">
                      <div class="col-6">
                        <input type="number" class="form-control form-inline" id="rankStart" name="rank_start" placeholder="보상 지급 순위" required v-model="item.rank_end">
                      </div>
                      <div class="col-6">
                        <input type="text" class="form-control form-inline" id="rewardItem" name="reward_item" placeholder="보상 품목" required v-model="item.reward_item">
                      </div>
                    </div>
                    <div id="rewardStep" class="row" v-if="item.reward_type === 'REWARD_TYPE02'">
                      <div class="col-12 input-group mb-1" v-for="(data, index) in rewardSteps">
                        <span class="input-group-prepend">
                            <span class="input-group-text">{{ index+1 }}</span>
                        </span>
                        <input type="number" class="form-control form-inline col-2" name="rank_starts[]" placeholder="시작 순위" required v-model="data.rank_starts">
                        <input type="number" class="form-control form-inline col-2" name="rank_ends[]" placeholder="종료 순위" required v-model="data.rank_ends">
                        <input type="text" class="form-control form-inline" name="reward_items[]" placeholder="보상 품목" required v-model="data.reward_items">
                        <span class="input-group-append">
                            <span class="input-group-text" @click="rewardMinus(item, data, index)"><i class="fa fa-lg" :class="index > 0 ? 'fa-minus-circle text-danger' : 'fa-check-circle' " aria-hidden="true"></i></span>
                        </span>
                      </div>
<!--                      <div class="col-12 input-group mb-1">-->
<!--                                        <span class="input-group-prepend">-->
<!--                                            <span class="input-group-text step-num">2</span>-->
<!--                                        </span>-->
<!--                        <input type="number" class="form-control form-inline col-2" name="rank_starts[]" placeholder="시작 순위" required>-->
<!--                        <input type="number" class="form-control form-inline col-2" name="rank_ends[]" placeholder="종료 순위" required>-->
<!--                        <input type="text" class="form-control form-inline" name="reward_items[]" placeholder="보상 품목" required>-->
<!--                        <span class="input-group-append">-->
<!--                                            <span class="input-group-text"><i class="fa fa-check-circle fa-lg" aria-hidden="true"></i></span>-->
<!--                                        </span>-->
<!--                      </div>-->
                    </div>
                    <div id="rewardSelect" class="row" v-if="item.reward_type === 'REWARD_TYPE03'">
                      <div class="col-12 input-group mb-1" v-for="(data, index) in rewardSelects">
                                        <span class="input-group-prepend">
                                            <span class="input-group-text">-</span>
                                        </span>
                        <input type="text" class="form-control form-inline" name="selected_items[]" placeholder="보상 품목" required v-model="data.selected_items">
                        <input type="text" class="form-control form-inline col-2" name="selected_ends[]" placeholder="수량" required v-model="data.selected_ends">
                        <span class="input-group-prepend">
                                            <span class="input-group-text">개</span>
                                        </span>
                        <span class="input-group-append">
                            <span class="input-group-text" @click="rewardMinus(item, data, index)"><i class="fa fa-lg" :class="index > 1 ? 'fa-minus-circle text-danger' : 'fa-check-circle' " aria-hidden="true"></i></span>
                        </span>
                      </div>
<!--                      <div class="col-12 input-group mb-1">-->
<!--                                        <span class="input-group-prepend">-->
<!--                                            <span class="input-group-text step-num">-</span>-->
<!--                                        </span>-->
<!--                        <input type="text" class="form-control form-inline" name="selected_items[]" placeholder="보상 품목" required>-->
<!--                        <input type="text" class="form-control form-inline col-2" name="selected_ends[]" placeholder="수량" required>-->
<!--                        <span class="input-group-prepend">-->
<!--                                            <span class="input-group-text">개</span>-->
<!--                                        </span>-->
<!--                        <span class="input-group-append">-->
<!--                            <span class="input-group-text" @click="rewardMinus(item, data, index)"><i class="fa fa-lg" :class="index > 1 ? 'fa-minus-circle text-danger' : 'fa-check-circle' " aria-hidden="true"></i></span>-->
<!--                        </span>-->
<!--                      </div>-->
                    </div>
                  </div>
<!--                  <div class="add-product">-->
<!--                    <div class="row">-->
<!--                      <div class="col-xl-9 xl-50 col-sm-6 col-9">-->
<!--                          &lt;!&ndash;src="../../assets/images/pro3/33.jpg"&ndash;&gt;-->
<!--                        <img-->
<!--                          src="../../assets/images/pro3/33.jpg"-->
<!--                          class="img-fluid image_zoom_1 blur-up lazyloaded"-->
<!--                        />-->
<!--                      </div>-->
<!--                      <div class="col-xl-3 xl-50 col-sm-6 col-3">-->
<!--                        <ul class="file-upload-product">-->
<!--                          <li v-for="(i, index) in image" :key="index">-->
<!--                            <img :src="i" class="box-input-file" />-->
<!--                            <feather-->
<!--                              style="cursor: pointer;"-->
<!--                              type="x"-->
<!--                              stroke-width="1"-->
<!--                              size="20px"-->
<!--                              class="icon"-->
<!--                              @click="removeImage(index)"-->
<!--                            ></feather>-->
<!--                          </li>-->
<!--                          <li>-->
<!--                            <div class="box-input-file">-->
<!--                              <input-->
<!--                                class="upload"-->
<!--                                type="file"-->
<!--                                @change="onFileChange"-->
<!--                              />-->
<!--                              <feather type="plus"></feather>-->
<!--                            </div>-->
<!--                          </li>-->
<!--                        </ul>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->

                </div>

                <div class="col-xl-6">

                </div>
              </div>
              <div class="row form-group">
                <div class="col-6 d-none">
                  <div class="form-group">

                  </div>
                  <label class="form-label">수집 유형</label>
                  <label class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" name="event_type" value="event_type01" v-model="item.event_type">
                    <span class="custom-control-label">일반수집형</span>
                  </label>
                  <label class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" name="event_type" value="event_type02" v-model="item.event_type">
                    <span class="custom-control-label">퀘스트수집형</span>
                  </label>
                  <small class="form-text text-muted">일반수집형 : 동일한 가중치의 일반적인 수집</small>
                  <small class="form-text text-muted">퀘스트수집형 : 퀘스트별 가중치를 부여하여 수집</small>
                </div>
                <div class="col-6">
                  <div class="form-group">

                  </div>
                  <label class="form-label d-inline" for="blockCount">조각수</label><small class="text-muted ml-2">사용자가 수집할 블록수를 선택하세요.</small>
                  <select class="form-control" id="blockCount" name="block_count" required v-model="item.block_count" @change="changCount">
                    <option value="">조각수를 선택하세요</option>
                    <option v-for="i in 9">{{i}}</option>
                  </select>
<!--                  <input type="text" class="form-control d-none" id="blockCountCustom" name="block_count_custom" placeholder="광고 조각수">-->

                </div>
              </div>
              <div class="row form-group" id="questBlock" v-if="questBlocks.length > 0">
                <div class="col-6" v-for="(data, index) in questBlocks">
                   <div class="card mb-1">
                        <div class="card-body p-3">
                              <div class="card-title d-inline">
                                    <label class="text-primary">블록 {{index+1}}</label>
                                    <div class="d-inline-flex input-group input-group-sm mb-1 float-right w-auto">
                                       <span class="input-group-prepend">
                                           <span class="input-group-text">수집방법</span>
                                           <select class="form-control form-control-sm form-control block_type" name="block_type" required v-model="data.block_type">
                                               <option value="" selected>선택</option>
                                               <option value="BLOCK_TYPE01">QR코드</option>
                                               <option value="BLOCK_TYPE02">링크</option>
                                               <option value="BLOCK_TYPE08">Youtube</option>
<!--                                               <option value="BLOCK_TYPE03">GPS</option>-->
<!--                                               <option value="BLOCK_TYPE04">QR+GPS</option>-->
                                               <option value="BLOCK_TYPE05">공유하기</option>
                                           </select>
                                       </span>
<!--                                       <span class="input-group-prepend ml-2 qr-unique d-none" style="display: none">-->
<!--                                           <span class="input-group-text ">단일QR사용</span>-->
<!--                                           <div class="input-group-text bg-white">-->
<!--                                               <label class="switch switch-sm switch-icon switch-outline-alt-primary form-control-label m-0">-->
<!--                                                   <input type="checkbox" class="switch-input form-check-input form-check" value="">-->
<!--                                                   <span class="switch-label"></span>-->
<!--                                                   <span class="switch-handle"></span>-->
<!--                                               </label>-->
<!--                                               <h4 class="mdi mdi-information-outline ml-2" data-toggle="popover" data-content="QR 단일 사용 시 목표인원의 1.5배만큼 QR코드가 생성되며 한번 수집된 QR은 재수집이 불가능합니다."></h4>-->
<!--                                           </div>-->
<!--                                       </span>-->
                                           <span class="input-group-prepend ml-2 is-direct" v-if="data.block_type === 'BLOCK_TYPE02' || data.block_type === 'BLOCK_TYPE05'">
                                           <span class="input-group-text ">즉시수집</span>
                                           <div class="input-group-text bg-white">
                                             <b-form-checkbox switch v-model="data.is_direct"></b-form-checkbox>

<!--                                               <label class="switch switch-sm switch-icon switch-outline-alt-primary form-control-label m-0">-->

<!--                                                   <input type="checkbox" name="is_direct[ + (i-1) + ]" class="switch-input form-check-input form-check" value="1">-->
<!--                                                   <span class="switch-label"></span>-->
<!--                                                   <span class="switch-handle"></span>-->
<!--                                               </label>-->
                                               <h4 class="mdi mdi-information-outline ml-2" data-toggle="popover" data-content="즉시수집 사용 시 힌트 확인 후 즉시 블록이 수집됩니다."></h4>
                                           </div>
                                       </span>
                                      </div>
                                </div>
                              <div class="input-group input-group-sm mb-1">
<!--                                    <span class="input-group-prepend block-level">-->
<!--                                        <span class="input-group-text">가중치</span>-->
<!--                                        <select class="form-control form-control-sm" name="block_levels[]">-->

<!--                                          </select>-->
<!--                                    </span>-->
                                    <span class="input-group-prepend">
                                      <span class="input-group-text">블록설명</span>
                                  </span>
                                    <input type="text" name="level_explains[]" class="form-control form-control-sm" placeholder="ex)홈페이지, 유튜브, 블로그등의 설명.. (입력 시 힌트로 표시됩니다.)" required v-model="data.level_explains">

                                </div>
                              <div class="input-group input-group-sm mb-1 explain-container" v-if="data.block_type === 'BLOCK_TYPE02'">
                                    <span class="input-group-prepend">
                                      <span class="input-group-text">수집대상 URL</span>
                                  </span>
                                    <input type="text" name="etc_explains[]" class="form-control form-control-sm" placeholder="ex)https://www.abc.com." v-model="data.etc_explain">
                                </div>
                              <div class="gps-container" style="display: none">
                                   <div class="input-group input-group-sm mb-1 ">
                                         <button type="button" class="btn btn-sm btn-outline-primary zip-search">주소검색</button>
                                         <input type="text" name="address[]" class="form-control form-control-sm address" readonly>
                                     </div>
                                   <div class="input-group input-group-sm mb-1 ">
                                         <span class="input-group-prepend">
                                         <span class="input-group-text">위도</span>
                                     </span>
                                         <input type="text" class="form-control form-control-sm latitude" placeholder="위도" name="latitude[]">
                                         <span class="input-group-prepend">
                                         <span class="input-group-text">경도</span>
                                     </span>
                                         <input type="text" class="form-control form-control-sm longitude" placeholder="경도" name="longitude[]">
                                         <span class="input-group-prepend">
                                         <span class="input-group-text">허용범위</span>
                                     </span>
                                         <input type="text" class="form-control form-control-sm col-2" placeholder="m단위" name="range[]">
                                         <span class="input-group-append">
                                         <span class="input-group-text">M</span>
                                     </span>
                                     </div>
                                </div>
                              <div class="youtube-container" v-if="data.block_type === 'BLOCK_TYPE08'">
                                   <div class="input-group input-group-sm mb-1 ">
                                         <span class="input-group-prepend">
                                         <span class="input-group-text">YouTube 공유주소</span>
                                     </span>
                                         <input type="text" class="form-control form-control-sm " placeholder="ex) https://youtu.be/M7lc1UVf-VE" name="youtube_id[]" v-model="data.youtube_id">
                                         <span class="input-group-prepend">
                                         <span class="input-group-text">수집 목표시간(초)</span>
                                     </span>
                                         <input type="number" class="form-control form-control-sm col-2" placeholder="숫자만 입력" name="goal_sec[]" v-model="data.goal_sec">
                                         <span class="input-group-prepend">
                                         <span class="input-group-text">초</span>
                                     </span>
                                     </div>
                                </div>
<!--                              <div class="input-group input-group-sm mb-1 ">-->
<!--                                    <span class="input-group-prepend">-->
<!--                                      <span class="input-group-text ">블록 수집 후 광고를 띄우겠습니까?</span>-->
<!--                                      <div class="input-group-text bg-white">-->
<!--                                        <b-form-checkbox v-model="checked" name="check-button" switch>-->
<!--                                          Switch Checkbox <b>(Checked: {{ checked }})</b>-->
<!--                                        </b-form-checkbox>-->
<!--                                          <label class="switch switch-icon switch-outline-alt-primary form-control-label m-0">-->
<!--                                              <input type="checkbox" name="is_adi+" class="switch-input form-check-input form-control-sm ad-term-check" value="1">-->
<!--                                              <span class="switch-label"></span>-->
<!--                                              <span class="switch-handle"></span>-->
<!--                                          </label>-->
<!--                                      </div>-->
<!--                                  </span>-->
<!--                                    <span class="input-group-prepend use-pool" style="display: none">-->
<!--                                      <span class="input-group-text ">광고 풀 사용</span>-->
<!--                                      <div class="input-group-text bg-white">-->
<!--                                          <label class="switch switch-icon switch-outline-alt-primary form-control-label m-0">-->
<!--                                              <input type="checkbox" name="use_pooli+" class="switch-input form-check-input form-control-sm use-pool-check" value="1">-->
<!--                                              <span class="switch-label"></span>-->
<!--                                              <span class="switch-handle"></span>-->
<!--                                          </label>-->
<!--                                      </div>-->
<!--                                  </span>-->
<!--                                </div>-->
                              <div class="ad-term" style="display: none">
                                    <div class="input-group input-group-sm mb-1 ">
                                          <span class="input-group-prepend">
                                          <span class="input-group-text">블록 광고 타이틀</span>
                                      </span>
                                          <input type="text" name="ad_title[]" class="form-control form-control-sm" placeholder="광고 팝업 제목을 보여줍니다.(미 작성 시 이미지만 노출됨)" required>
                                      </div>
                                    <div class="input-group input-group-sm mb-1 ">
                                          <span class="input-group-prepend">
                                          <span class="input-group-text">블록 광고 이미지</span>
                                      </span>
                                          <div class="custom-file">
                                                <input type="file" name="ad_img[]" class="custom-file-input interval-file" required>
                                                <label class="custom-file-label">광고 이미지 선택</label>
                                            </div>
                                      </div>
                                    <div class="input-group input-group-sm mb-1 ">
                                          <span class="input-group-prepend">
                                          <span class="input-group-text">이동 URL</span>
                                      </span>
                                          <input type="text" name="ad_url[]" class="form-control form-control-sm" placeholder="이미지 클릭 시 이동할 URL - http, https 형식으로 입력">
                                      </div>
                                </div>
                              <div class="ad-pool" style="display: none">
                                    <div class="input-group input-group-sm mb-1">
                                          <span class="input-group-prepend">
                                          <span class="input-group-text ">노출방법</span>
                                          <div class="input-group-text bg-white">
                                              <div class="form-check form-check-inline">
                                                  <input class="form-check-input form-control-sm view_type" type="radio" name="view_type" id="viewType1i+" value="VIEW_TYPE01" checked>
                                                  <label class="form-check-label form-check-label-small" for="viewType1i+">단일 광고 노출</label>
                                              </div>
                                          </div>
                                          <div class="input-group-text bg-white">
                                              <div class="form-check form-check-inline">
                                                  <input class="form-check-input form-control-sm view_type" type="radio" name="view_type" id="viewType2i+" value="VIEW_TYPE02">
                                                  <label class="form-check-label text-sm-left" for="viewType2i+">분류 내 광고 랜덤 노출</label>
                                              </div>
                                          </div>
                                       </span>
                                           <span class="input-group-prepend">
                                           <div class="input-group-text bg-white">
                                               <input type="button" class="btn btn-sm btn-outline-primary choose-pool" value="선택하기">
                                           </div>
                                       </span>
                                      </div>
                                    <div class="input-group input-group-sm mb-1">
                                          <span class="" id="choose-title"></span>
                                      </div>
                                </div>
                           </div>
                     </div>
                </div>
              </div>
              <div class="row form-group">
                <div class="col">
                  <label class="form-label d-inline" for="">토근지급 방식</label>
                  <label class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" name="token_method" value="token_method01" checked="" v-model="item.token_method">
                    <span class="custom-control-label">참여완료자 균등 분배</span>
                  </label>
                  <label class="custom-control custom-radio custom-control-inline">
                    <input type="radio" class="custom-control-input" name="token_method" value="token_method02" v-model="item.token_method">
                    <span class="custom-control-label">참여완료자별 정액 지급</span>
                  </label>
                  <small id="typeNormalHelp" class="form-text text-muted">참여완료자 균등 분배 : 블록 수집완료한 사용자에게 전체비용을 균등하게 분배함</small>
                  <small id="typeQuestHelp" class="form-text text-muted">참여완료자별 정액 지급 : 지정한 토큰을 블록 수집완료한 사용자에게 지급함</small>
                </div>
              </div>
              <div id="methos01">
                <div class="row form-group">
                  <div class="col-6">
                    <label class="form-label d-inline" for="">목표인원수</label>
                    <input type="text" class="form-control" id="targetNumber" name="target_number" aria-describedby="adNameHelp" placeholder="목표인원수" required v-model="item.target_number" @keyup="targetNumber">
                  </div>
                  <div id="amountArea" class="col-6">
                    <label class="form-label d-inline" for="">결제예정금액</label><small id="" class="form-text text-muted d-inline ml-2">목표인원수 * 500원 자동계산</small>

                    <div class="input-group mb-1">
                      <span class="input-group-prepend">
                          <span class="input-group-text">₩</span>
                      </span>
                      <input type="text" class="form-control" id="payAmount" name="payment" required readonly :value="item.payment">
                    </div>
                  </div>
                </div>
<!--                <div class="row form-group">-->
<!--                  <div class="col-6">-->
<!--                    <label class="form-label">추가인원 허용여부</label>-->
<!--                    <label class="switch switch-icon switch-outline-alt-primary form-control-label ml-2" style="margin-top: -4px;">-->
<!--                      <input type="checkbox" id="addPerson" name="add_person" class="switch-input form-check-input form-control-sm" value="">-->
<!--                      <span class="switch-label"></span>-->
<!--                      <span class="switch-handle"></span>-->
<!--                    </label>-->
<!--                    <small class="text-muted ml-2">목표인원 초과시 보유 CT내 추가 인원 허용여부</small>-->
<!--                  </div>-->
<!--                  <div class="col-6">-->
<!--                    <label class="form-label">추가허용인원</label>-->
<!--                    <input type="text" id="addNumber" name="add_number" class="form-control d-inline-block w-auto ml-2" disabled>-->
<!--                    <small class="text-muted ml-2">빈칸 시 제한없음</small>-->
<!--                  </div>-->
<!--                </div>-->
              </div>
              <div id="methos02" style="display: none;">
                <div class="row form-group">
                  <div class="col-6">
                    <label class="form-label d-inline" for="">참여완료 인원당 토큰 지급액</label>
                    <div class="input-group mb-1">
                      <input type="text" class="form-control" id="tokenPayment" name="token_payment" aria-describedby="adNameHelp" placeholder="인원별 토큰 지급액 - 숫자만 입력">
                      <span class="input-group-append">
                          <span class="input-group-text">원</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <button type="button" class="btn btn-primary btn-pill mr-1" id="btn-save" @click="eventSave">이벤트생성</button>
                <router-link to="/events/eventList"><button type="button" class="btn btn-danger btn-pill ml-1" data-dismiss="modal">돌아가기</button></router-link>
<!--                <div class="text-muted mt-2">{{$site->app_url}}에 게시됩니다.</div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/ko';
import nodeApi from "../../api/nodeApi";
import router from "../../router";

export default {
  components: {DatePicker},
  data() {
    return {
      counter: 0,
      content: "<h1>Some initial content</h1>",
      image: [],
      item: {
        event_desc : '',
        reward_type : 'REWARD_TYPE01',
        event_type : 'event_type01',
        token_method : 'token_method01',
        payment: 0,
        rank_start: '',
        rank_end: '',
        reward_item: '',
        block_count: '',
      },
      rewardSteps : [{rank_starts: '', rank_ends: '', reward_items : ''}],
      rewardSelects : [{reward_items : '', reward_qtys: ''}, {reward_items : '', reward_qtys: ''}],
      questBlocks : [],
      preview: '',
      file_name: '원본 이미지 파일 선택',
      previewMission: '',
      mission_file_name: '원본 이미지 파일 선택',
      previewDetail: '',
      detail_file_name: '원본 이미지 파일 선택',
    };
  },
  methods: {


    eventSave() {
      this.$log.debug(['eventSave', this.item, this.questBlocks]);
      let managerInfo = localStorage.getItem('userInfo');
      if (managerInfo) {
        managerInfo = JSON.parse(managerInfo);
      }

      const frm = new FormData();

      frm.append('site_code', managerInfo.site_code);
      frm.append('event_name', this.item.event_name);
      frm.append('event_image', this.item.event_image);
      frm.append('mission_image', this.item.mission_image);
      frm.append('detail_image', this.item.detail_image);
      if (this.item.detail_images) {
        for (let i = 0; i < this.item.detail_images.length; i++) {
          frm.append('detail_images', this.item.detail_images[i]);
        }
      }

      frm.append('event_desc', this.item.event_desc);
      frm.append('event_type', this.item.event_type);
      frm.append('start_date', this.item.start_date);
      frm.append('end_date', this.item.end_date);
      frm.append('target_number', this.item.target_number);
      frm.append('payment', this.item.payment);
      frm.append('reward_type', this.item.reward_type);
      if (this.item.reward_type === 'REWARD_TYPE01') {
        frm.append('rank_end', this.item.rank_end)
        frm.append('reward_item', this.item.reward_item)
      }

      if (this.item.reward_type === 'REWARD_TYPE02') {
        frm.append('reward_steps', JSON.stringify(this.rewardSteps))
      }

      if (this.item.reward_type === 'REWARD_TYPE03') {
        frm.append('reward_selects', JSON.stringify(this.rewardSelects))
      }
      // frm.append('discount_range', JSON.stringify(this.discountRange));
      frm.append('quest_blocks', JSON.stringify(this.questBlocks))
      this.$log.debug(['from', frm, frm.entries()]);

      nodeApi.postEvent(frm).then( res => {
        this.$log.debug(['postEvent res', res]);
        if (res.data.success) {
          nodeApi.showToast('이벤트 등록 완료', 'success');
          console.log(['info hash ', res.data.result_hash]);
          router.push("/events/eventList");

        } else {
          nodeApi.showToast(res.data.msg);
        }

      }).catch( err => {
        this.$log.error(err.toString());
      });
    },
    onFileChange(e) {
      var files = e.target.files;
      if (!files.length) return;

      this.createImage(files[0]);
    },
    decrement() {
      if (this.counter > 1) this.counter--;
    },
    createImage(file) {
      var reader = new FileReader();
      reader.onload = e => {
        this.image.push(e.target.result);
      };
      reader.readAsDataURL(file);
    },
    removeImage: function(index) {
      console.log("index", index);
      this.image.splice(index, 1);
      //  this.image = "";
    },
    imagePreview(e) {
      const file = e.target.files[0];

      this.file_name = file.name;
      this.item.event_image = file;
      this.preview = URL.createObjectURL(file);

    },
    imageMissionPreview(e) {
      const file = e.target.files[0];

      this.mission_file_name = file.name;
      this.item.mission_image = file;
      this.previewMission = URL.createObjectURL(file);

    },
    imageDetailPreview(e) {
      const file = e.target.files[0];

      this.detail_file_name = file.name;
      this.item.detail_image = file;
      this.previewDetail = URL.createObjectURL(file);

    },
    resetReward() {
      this.$log.debug(['resetReward', this.item.reward_type]);

    },
    rewardPlus() {
      this.$log.debug(['rewardPlus', this.item.reward_type])
      if (this.item.reward_type === 'REWARD_TYPE02') {
        this.rewardSteps.push({rank_starts: '', rank_ends: '', reward_items : ''});
      }

      if (this.item.reward_type === 'REWARD_TYPE03') {
        this.rewardSelects.push({reward_items : '', reward_qtys: ''});
      }
    },
    rewardMinus(item, data, idx) {

      this.$log.debug(['rewardMinus', item, data, idx])
      if (item.reward_type === 'REWARD_TYPE02' && idx > 0) {
        this.rewardSteps.splice(this.rewardSteps.length-1,1);
      }

      if (item.reward_type === 'REWARD_TYPE03' && idx > 1) {
        this.rewardSelects.splice(this.rewardSelects.length-1,1);
      }
      // this.discountRange.splice(this.discountRange.length-1,1);
    },
    changCount() {
      this.$log.debug(['changCount', this.item.block_count]);
      if (this.item.block_count > 0) {
        this.questBlocks = [];
        for (let i = 0; i < this.item.block_count; i++) {
          this.questBlocks.push({block_type: '', level_explains: ''});
        }
      }
    },
    targetNumber(e) {
      this.$log.debug(['targetNumber', e.target.value, this.item.target_number])
      if (this.item.target_number) {
        this.$log.debug(['calc', this.item.target_number])
        // this.item.payment = e.target.value * 500;
        this.item.payment = this.item.target_number * 500;
        this.$log.debug(['payment', this.item.payment])
      } else {
        this.item.payment = 0;
      }
    }
  }
};
</script>

<style scoped>
 .ck-content { height:500px; }
 .icon{
position: absolute;
  top: 8px;
  right: 16px;
 }

 /*.mx-datepicker {*/
 /*  width: 8rem;*/
 /*}*/
</style>
