export const defaultLocale = "en";
export const localeOptions = [
    { id: 'en', name: 'English', icon: 'us' },
    { id: 'es', name: 'Spanish', icon: 'es' },
    { id: 'pt', name: 'Portuguese', icon: 'pt' },
    { id: 'fr', name: 'French', icon: 'fr' },
];

let IMG_URL = 'https://api.wonhago.com';

if (process.env.NODE_ENV === 'development') {
    IMG_URL = 'http://localhost:3004';
}
export const imgUrl = IMG_URL;
